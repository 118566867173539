<template>
<div>
    <NewCloudGameLauncher
            ref="launchGame"
            @joinCloudGame="requestJoinCloudGame"
            @changeDialogStatus="changeDialogStatus"
            :dialogStatus="dialogStatus"
    ></NewCloudGameLauncher>
    <div id="gamePlayContainer"></div>
</div>
</template>

<script>
    import localGameState from '@/utils/localGameState'
    import lcgWsUtil from '@/utils/lcgWsUtil'
    export default {
        name: 'Index',
        data() {
            return {
                _ws_connection:null,
                network_info:'',
                dialogStatus: false, // 控制弹窗状态
            }
        },
        mounted(){
            let password = this.$route.params.password;
            let ws_token = this.$route.params.token;
            if(!ws_token || !password){
                this._startCloudGameFailed('加入游戏口令错误');
                return;
            }
            this.$refs.launchGame.requestJoinGame(password,ws_token);
        },
        methods:{
            // 子组件改变弹窗状态
            changeDialogStatus (status) {
                this.dialogStatus = status
            },
            requestJoinCloudGame(nsConn){
                this._ws_connection = nsConn;
                this.joinCloudGame(nsConn,this._startCloudGameFailed)

            },

            joinCloudGame(nsConn,onFailed){
                let game_info = this.$store.state.game.game_info;
                if(!game_info){
                    return
                }

                this.session_id =game_info.launch_params.session_id;
                this.play_token = game_info.launch_params.play_token;
                this.game_params = game_info.launch_params.game_params;
                this.cloud_provider = game_info.launch_params.cloud_provider;
                this.cloud_server = game_info.launch_params.cloud_server;
                let join_token =  this.play_token.invite_code;
                const config = {
                    game: 'Game',
                    server:  this.cloud_server,
                    width: document.documentElement.clientWidth,
                    height: document.documentElement.clientHeight,
                    target: '#gamePlayContainer',
                    auth: {
                    play_token: this.play_token.token,
                    provider_id: 't2cn',
                    channel: 't2cn_game'
                    },
                    party: {
                        joinToken: join_token,
                    },
                    launchParams: {
                        setting: {
                            autoviewport: true,
                            embed: true,
                            simpleloading: true
                        }
                    }
                };
                OnNine.initializeAsync(config).then(onnine => {
                    onnine.addEventListener('status', this.onUbStatusChanged);
                    onnine.addEventListener('error', function(error) {
                        // console.error(`ErrorCode: ${error.code}`);
                        // console.error(`ErrorMessage: ${error.message}`);
                        let _err_code = error.code;
                        let _err_msg = error.message;
                        let report_msg = lcgWsUtil.wsMessageBody(10066, "出错！"+_err_msg,_err_code);
                        nsConn.emit("report", report_msg);
                        nsConn.conn.close();
                        onFailed('云端游戏启动失败: '+error.message);
                    });
                    onnine.play();
                });
            },

            _startCloudGameFailed(error){
                //this.$refs.launchGame.onCloudGameError(error);
                localGameState.stopGame(error);
            },
            onUbStatusChanged(status){
                let report_msg = lcgWsUtil.wsMessageBody(10066, "Ubitus状态码",status);
                this._ws_connection.emit("report", report_msg);
                switch(status){
                    case 'firstframearrived':
                        this._ws_connection.emit("report", lcgWsUtil.wsMessageBody(10000, "success"));
                    break;
                    case 'close':
                        localGameState.stopGame('云端游戏关闭');
                    break
                    case 'lowestbandwidth':
                        this.network_info='网络状态较差';
                    break;
                    case 'normalbandwidth':
                        this.network_info='';
                    break;
                }
            },
        }
    }
 </script>
 <style lang="scss">
@import "@/styles/game/game";
</style>
